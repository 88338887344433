import React, { Fragment, useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { TextLink, TypographyComponent, TypographyStyling } from "gx-npm-ui";
import styles from "./sign-up-form-header.styles.module.scss";
import { SignUpFormContext } from "../sign-up-form.context";
import ErrorBanner from "../error-banner/error-banner.component";
import { commonTheme } from "gx-npm-common-styles";

const SignUpFormHeader = () => {
  const { t } = useTranslation();
  const { surveyId, page, errorText } = useContext(SignUpFormContext);

  const [headerStyling, setHeaderStyling] = useState<TypographyStyling>("p2");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= commonTheme.breakpoints.values.md) {
        setHeaderStyling("p1");
      } else {
        setHeaderStyling("p2");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <div className={styles.signUpHeading}>
        <div className={styles.signUpHeadingTitle}>
          <TypographyComponent styling={headerStyling} boldness={"medium"} rootClassName={classnames(styles.title)}>
            {page === 2 ? t("Tell us about yourself") : t("Create a Gartner BuySmart account.")}
          </TypographyComponent>
          {!surveyId && (
            <TypographyComponent styling="p4" boldness="regular" rootClassName={classnames(styles.pagination)}>
              {page}
              {t(" of 2")}
            </TypographyComponent>
          )}
        </div>
        <div className={styles.signUpHeadingLogin}>
          {page === 1 && (
            <>
              <TextLink
                rootClassName={classnames(styles.gxTextLink)}
                href="/login"
                text={t("Log in")}
                variant="primary"
              />
              <TypographyComponent styling="p3" element="p">
                {t("if you already have an account")}
              </TypographyComponent>
            </>
          )}
          {page === 2 && (
            <>
              <TypographyComponent styling="p3" element="p">
                {t("To personalize your experience, tell us a little bit about what kind of work you do.")}
              </TypographyComponent>
            </>
          )}
        </div>
        {!!errorText && (
          <div className={styles.errorContainer}>
            <ErrorBanner message={errorText} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SignUpFormHeader;
