import { TypographyComponent } from "gx-npm-ui";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import ErrorBanner from "../error-banner/error-banner.component";
import { LoginFormContext } from "../login-form.context";
import styles from "./form-header.module.scss";

const FormHeader = () => {
  const { t } = useTranslation();
  const { errorText } = useContext(LoginFormContext);

  return (
    <Fragment>
      <header className={styles.loginHeading}>
        <div className={styles.headingWrapper}>
          <TypographyComponent styling={"p1"} boldness={"medium"} element={"h2"}>
            {t("Access Gartner BuySmart")}
          </TypographyComponent>
          <TypographyComponent rootClassName={styles.subTitle} boldness={"regular"} styling={"p3"} element={"p"}>
            {t("Use your existing Gartner.com username and password.")}
          </TypographyComponent>
        </div>
      </header>
      {!!errorText && (
        <div>
          <ErrorBanner message={errorText} />
        </div>
      )}
    </Fragment>
  );
};

export default FormHeader;
