import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Select,
  TextField,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { ChangeEvent, Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SignUpFormContext } from "../sign-up-form.context";
import { CompanySize, Industry, ProfileFunction, RegistrationProfileForm, Role } from "../sign-up-form.types";
import styles from "./sign-up-profile-fields.styles.module.scss";
import { GCOM_4110__guestLoginEnhancements } from "../../../../lib/feature-flags";

const SignUpProfileFields = () => {
  const { t } = useTranslation();
  const { setIsCompleteProfileForm, profile, setProfile, gcomData } = useContext(SignUpFormContext);
  const [selectedFunctionRoles, setSelectedFunctionRoles] = useState<Role[]>([]);
  const isFF4110On = useFeatureFlag(GCOM_4110__guestLoginEnhancements);

  useEffect(() => {
    const { companySize, industry, function: func, role, title } = profile as RegistrationProfileForm;
    if (companySize && industry && func && role && title) {
      setIsCompleteProfileForm(true);
    } else {
      setIsCompleteProfileForm(false);
    }
  }, [profile, setIsCompleteProfileForm]);

  const handleChange = (event: ChangeEvent<{ value: string }> | ChangeEvent<HTMLInputElement>, inputType: string) => {
    const targetValue = (event.target as HTMLInputElement).value as string;
    let selectedOption: CompanySize | Industry | ProfileFunction | Role | undefined;

    switch (inputType) {
      case "companySize":
        selectedOption = gcomData.companySize.find((size) => size.name === targetValue);
        setProfile((prevProfileData) => ({
          ...prevProfileData,
          companySize: selectedOption ? selectedOption.name : "",
          companySizeId: selectedOption ? selectedOption.id : null,
        }));
        break;
      case "industry":
        selectedOption = gcomData.industries.find((industry) => industry.name === targetValue);
        setProfile((prevProfileData) => ({
          ...prevProfileData,
          industry: selectedOption ? selectedOption.name : "",
          industryId: selectedOption ? selectedOption.id : null,
        }));
        break;
      case "function":
        selectedOption = gcomData.functions.find((func) => func.name === targetValue);
        if (selectedOption) {
          setSelectedFunctionRoles((selectedOption as ProfileFunction).roles);
          setProfile((prevProfileData) => ({
            ...prevProfileData,
            function: selectedOption ? selectedOption.name : "",
            functionId: selectedOption ? selectedOption.id : null,
            role: "",
            roleId: null,
          }));
        }
        break;
      case "role":
        selectedOption = selectedFunctionRoles.find((role) => role.name === targetValue);
        setProfile((prevProfileData) => ({
          ...prevProfileData,
          role: selectedOption ? selectedOption.name : "",
          roleId: selectedOption ? selectedOption.id : null,
        }));
        break;
      default:
        setProfile((prevProfileData) => ({
          ...prevProfileData,
          [inputType]: targetValue,
        }));
        break;
    }
  };

  return (
    <Fragment>
      <div className={!isFF4110On ? styles.profileContainer : styles.profileContainerGCOM4110}>
        <FeatureFlagBooleanContainer flagName={GCOM_4110__guestLoginEnhancements}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p4"} color={"iron"} boldness={"medium"} rootClassName={styles.groupTitle}>
              {t("About you")}
            </TypographyComponent>
            <Select
              disabled={gcomData.functions?.length === 0}
              items={gcomData.functions.map((func) => func.name)}
              isRequired
              label={`${t("Function")}`}
              {...(gcomData.functions.length > 5 && { menuHeight: "210px" })}
              onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "function")}
              popOverPaperClassName="gx-profile-select-paper-popover"
              rootClassName={styles.userInput}
              borderRadius={"4px"}
            />
            <Select
              disabled={selectedFunctionRoles.length === 0}
              items={selectedFunctionRoles.map((role) => role.name)}
              isRequired
              label={`${t("Role")}`}
              {...(selectedFunctionRoles.length > 5 && { menuHeight: "210px" })}
              onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "role")}
              popOverPaperClassName="gx-profile-select-paper-popover"
              rootClassName={styles.userInput}
              borderRadius={"4px"}
            />
            <TextField
              label="Job title"
              placeholder={t("Job title")}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, "title")}
              rootClassName={styles.userInput}
              required
            />
            <TypographyComponent styling={"p4"} color={"iron"} boldness={"medium"} rootClassName={styles.groupTitle}>
              {t("About your company")}
            </TypographyComponent>
            <Select
              disabled={gcomData.companySize?.length === 0}
              items={gcomData.companySize.map((size) => size.name)}
              isRequired
              label={`${t("Company size")}`}
              {...(gcomData.companySize.length > 5 && { menuHeight: "210px" })}
              onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "companySize")}
              popOverPaperClassName="gx-profile-select-paper-popover"
              rootClassName={styles.userInput}
              borderRadius={"4px"}
            />
            <Select
              disabled={gcomData.industries?.length === 0}
              items={gcomData.industries.map((industry) => industry.name)}
              isRequired
              label={`${t("Industry")}`}
              {...(gcomData.industries.length > 5 && { menuHeight: "210px" })}
              onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "industry")}
              popOverPaperClassName="gx-profile-select-paper-popover"
              rootClassName={styles.userInput}
              borderRadius={"4px"}
            />
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Select
              disabled={gcomData.companySize?.length === 0}
              items={gcomData.companySize.map((size) => size.name)}
              isRequired
              label={`${t("Company size")}`}
              {...(gcomData.companySize.length > 5 && { menuHeight: "210px" })}
              onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "companySize")}
              popOverPaperClassName="gx-profile-select-paper-popover"
              rootClassName="gx-profile-input"
              borderRadius={"4px"}
            />
            <Select
              disabled={gcomData.industries?.length === 0}
              items={gcomData.industries.map((industry) => industry.name)}
              isRequired
              label={`${t("Industry")}`}
              {...(gcomData.industries.length > 5 && { menuHeight: "210px" })}
              onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "industry")}
              popOverPaperClassName="gx-profile-select-paper-popover"
              rootClassName="gx-profile-input"
              borderRadius={"4px"}
            />
            <Select
              disabled={gcomData.functions?.length === 0}
              items={gcomData.functions.map((func) => func.name)}
              isRequired
              label={`${t("Function")}`}
              {...(gcomData.functions.length > 5 && { menuHeight: "210px" })}
              onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "function")}
              popOverPaperClassName="gx-profile-select-paper-popover"
              rootClassName="gx-profile-input"
              borderRadius={"4px"}
            />
            <Select
              disabled={selectedFunctionRoles.length === 0}
              items={selectedFunctionRoles.map((role) => role.name)}
              isRequired
              label={`${t("Role")}`}
              {...(selectedFunctionRoles.length > 5 && { menuHeight: "210px" })}
              onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "role")}
              popOverPaperClassName="gx-profile-select-paper-popover"
              rootClassName="gx-profile-input"
              borderRadius={"4px"}
            />
            <TextField
              label="Title"
              placeholder={t("Title")}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, "title")}
              required
            />
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
    </Fragment>
  );
};

export default SignUpProfileFields;
